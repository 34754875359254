export const fields = [
    { key: 'index', label: "No", _style:'min-width:50px'},
    { key: 'select', label: "", _style:'min-width:50px' },
    { key: 'name', label: "Nama Penyedia", _style:'min-width:100px' },
    { key: 'notes', label: "Keterangan", _style:'min-width:100px' },
    { key: "address", label: "Alamat", _style:'min-width:50px'},
    { key: 'longitude',label: "Longitude", _style:'min-width:100px;' },
    { key: 'latitude', label: "Latitude", _style:'min-width:100px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
